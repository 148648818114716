import React, { useContext } from "react";
import AuthContext from "../context/AuthContext"; // Assuming this is the correct path to your AuthContext
import "./login.css";

const LoginPage = () => {
  const { loginUser } = useContext(AuthContext);

  return (
    <div className="login-container">
      <div className="login-content">
        <h2>Login</h2>
        <form onSubmit={loginUser} className="login-form">
          <div className="form-group">
            <input
              type="text"
              name="username"
              placeholder="Enter Username"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Enter Password"
              className="form-control"
            />
          </div>
          <button type="submit" className="btn btn-secondary btn-block">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
