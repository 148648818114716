import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "./sidebar";
import axios from "axios";
import { API_URL } from "../components/api";
import "./navbar.css";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const Header = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isSidebarOpen, setSidebarOpen] = useState(
    () => localStorage.getItem("sidebarOpen") === "true"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/dealers/info/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [user.username]);

  const openSidebar = (e) => {
    e.stopPropagation();
    setSidebarOpen(true);
    localStorage.setItem("sidebarOpen", "true");
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    localStorage.setItem("sidebarOpen", "false");
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    setShowChangePasswordModal(true);
    handleMenuClose();
  };

  const handleSubmitPasswordChange = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/administration/changepassword/`,
        {
          username: user.username,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(response);
      // Close the modal after successful password change
      setShowChangePasswordModal(false);
      // Optionally, you might want to handle any additional actions after changing the password
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="nav">
      <div className="nav-left">
        <button className="btn hamburger" onClick={openSidebar}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>{user.username}</MenuItem>
          <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
        </Menu>
      </div>
      <div className="nav-right">
        <h4
          style={{ marginTop: "15px", cursor: "pointer" }}
          onClick={handleMenuClick}
        >
          {data.company_name}
        </h4>
        {user ? (
          <div className="logout" onClick={logoutUser}>
            <h4>Logout</h4>
          </div>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </div>

      <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />

      {/* Change Password Modal */}
      <Dialog
        open={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter your new password:</DialogContentText>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowChangePasswordModal(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmitPasswordChange} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
