// Sidebar.js
import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
  faMoneyBill,
  faPiggyBank,
  faWarehouse,
  faFileInvoice,
  faFileInvoiceDollar,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons"; // Import the Font Awesome icons you need
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import { NavLink } from "react-router-dom";
import axios from "axios";
import "./sidebar.css";
import Wizard from "./wizard";
import ResellerWizard from "./ResellerWizard";

import Modal from "react-bootstrap/Modal";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoImage from "./oss-logo.svg"; // Import your logo image

const Sidebar = ({ isOpen, closeSidebar }) => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [shift, setShift] = useState("");

  const sidebarRef = useRef(null);
  console.log(data);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [wizardshow, setShowwizard] = useState(false);
  const handleClosewizard = () => setShowwizard(false);
  const handleShowwizard = () => setShowwizard(true);

  const handleNavLinkClick = () => {
    // Close the sidebar after 2 seconds
    setTimeout(() => {
      closeSidebar();
    }, 2000);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchShift = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/shift/status/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setShift(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchShift();

    // Add a click event listener to the document to close the sidebar when clicking outside of it
    const handleOutsideClick = (e) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [user.username, isOpen, closeSidebar]);

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
      <div className="sidebar-content">
        <div className="nav-links">
          <h3>NazaNet</h3>
          <NavLink
            exact
            to="/"
            className="nav-link"
            activeClassName="active"
            onClick={handleNavLinkClick}
          >
            <FontAwesomeIcon
              icon={faTachometerAlt}
              style={{ marginRight: "13px" }}
            />{" "}
            Dashboard
          </NavLink>
          <NavLink
            exact
            to="/clients"
            className="nav-link"
            activeClassName="active"
            onClick={handleNavLinkClick}
          >
            <FontAwesomeIcon icon={faUsers} style={{ marginRight: "10px" }} />{" "}
            Clients
          </NavLink>

          {data === "Dealer" && (
            <NavLink
              exact
              to="/plans/resellers"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
              Internet Plans
            </NavLink>
          )}

          {(data === "Reseller" || data === "yes") && (
            <NavLink
              exact
              to="/plans/internet"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faWifi} style={{ marginRight: "10px" }} />{" "}
              Internet Plans
            </NavLink>
          )}
          {(data === "Reseller" || data === "yes") && (
            <NavLink
              exact
              to="/resellers"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
              Resellers
            </NavLink>
          )}
          {(data === "Reseller" || data === "yes") && (
            <NavLink
              to="/subscriptions/internet"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />{" "}
              Subscriptions
            </NavLink>
          )}
          {data === "Dealer" && (
            <NavLink
              to="/subscriptions/internet/reseller"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />{" "}
              Subscriptions
            </NavLink>
          )}
          {(data === "Dealer" || data === "Reseller") && (
            <NavLink
              to="/admin/console"
              className="nav-link"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faCog} /> Accounting
            </NavLink>
          )}
          {data === "Reseller" && (
            <button onClick={handleShow} className="AddClient">
              <i className="fa fa-user-plus" aria-hidden="true"></i> Add
            </button>
          )}
          {data === "Dealer" && (
            <button onClick={handleShowwizard} className="AddClient">
              <i className="fa fa-user-plus" aria-hidden="true"></i> Add
            </button>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Wizard closeModal={handleClose} />
      </Modal>
      <Modal show={wizardshow} onHide={handleClosewizard}>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <ResellerWizard closeModal={handleClosewizard} />
      </Modal>
    </div>
  );
};

export default Sidebar;
