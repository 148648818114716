import React, { useState, useEffect, useContext, useMemo } from "react";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import "./SubDealers.css";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";

const PAGE_SIZE = 10;

function TransactionCard({ transaction }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          {transaction.dealer_transaction_details}
        </Typography>
        <Typography color="text.secondary">
          Amount: {transaction.amount}
        </Typography>
        <Typography color="text.secondary">
          Action: {transaction.action}
        </Typography>
        <Typography color="text.secondary">
          Credit Account: {transaction.credit_account}
        </Typography>
        <Typography color="text.secondary">
          Debit Account: {transaction.debit_account}
        </Typography>
        <Typography color="text.secondary">
          Date: {new Date(transaction.creation_date).toLocaleString()}
        </Typography>
      </CardContent>
    </Card>
  );
}

function TransactionsTable() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/dealers/transaction/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setTransactions(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const totalPages = useMemo(
    () => Math.ceil(transactions.length / PAGE_SIZE),
    [transactions.length]
  );
  const startIndex = useMemo(
    () => (currentPage - 1) * PAGE_SIZE,
    [currentPage]
  );
  const endIndex = useMemo(() => startIndex + PAGE_SIZE, [startIndex]);
  const currentTransactions = useMemo(
    () => transactions.slice(startIndex, endIndex),
    [transactions, startIndex, endIndex]
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="ClientTable">
      <Grid container spacing={2}>
        {!loading &&
          currentTransactions.map((transaction) => (
            <Grid
              key={transaction.dealer_transaction_id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <TransactionCard transaction={transaction} />
            </Grid>
          ))}
      </Grid>
      <div className="pagination">
        <Button
          variant="contained"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default TransactionsTable;
