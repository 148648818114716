import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";

function Formo(props) {
  let [notes, setvillage] = useState([]);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  function refreshPage() {
    window.location.reload(false);
  }
  useEffect(() => {
    let isMounted = true;

    const getvillage = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/villages/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: "admin",
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setvillage(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getvillage();

    return () => {
      isMounted = false;
    };
  }, []);

  // Rest of the component code
  const options = notes.map((item) => {
    return {
      label: item.village_name,
      value: item.village_id,
    };
  });
  console.log(props.sub);
  console.log(props.add);
  console.log(props.inf);
  console.log(props.fir);
  console.log(props.mid);
  console.log(props.las);
  console.log(props.pho);
  console.log(props.addr);
  let vilo = props.vill;
  let v = props.sub;
  let f = props.add;
  let l = props.inf;
  let y = props.fir;
  let z = props.mid;
  let d = props.las;
  let i = props.pho;
  let k = props.addr;
  const [firstname, setfirst] = useState("");
  const [middlename, setmiddles] = useState("");
  const [lastname, setlast] = useState("");
  const [address, setaddress] = useState("");
  const [phone, setphone] = useState("");
  const [village, setvill] = useState("");
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/administration/clients/`,
      {
        client_id: v,
        info_id: l,
        address_id: f,
        Action: "Put",
        first_name: firstname,
        email_address: "-",
        address_string: address,
        phone_number: phone,
        middle_name: middlename,
        last_name: lastname,
        village_id: village,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="name">
          <div className="form-group">
            <label>
              First Name:
              <input
                className="form-control"
                type="text"
                value={firstname}
                placeholder={y}
                onChange={(e) => setfirst(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Middle Name:
              <input
                className="form-control"
                type="text"
                value={middlename}
                placeholder={z}
                onChange={(e) => setmiddles(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Last Name:
              <input
                className="form-control"
                type="text"
                value={lastname}
                placeholder={d}
                onChange={(e) => setlast(e.target.value)}
              />
            </label>
          </div>
        </div>

        <label>
          Phone:
          <input
            className="form-control"
            type="number"
            value={phone}
            placeholder={i}
            onChange={(e) => setphone(e.target.value)}
          />
        </label>
        <label>
          Village:
          <Select
            className="search-line"
            placeholder={vilo}
            options={options}
            onChange={(opt) => setvill(opt.value)}
          />
        </label>
        <label>
          Address:
          <input
            className="form-control"
            type="text"
            value={address}
            placeholder={k}
            onChange={(e) => setaddress(e.target.value)}
          />
        </label>
        <div className="fo">
          <button type="submit" className="btn btn-primary btn-md">
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Formo;
