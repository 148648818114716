import React, { useState, useContext } from "react";
import Axios from "axios";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";

function Form(props) {
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  function refreshPage() {
    //window.location.reload(false);
  }
  let v = props.sub;
  let h = props.info;
  let f = props.balance;
  let { user } = useContext(AuthContext);

  const [firstname, setfirst] = useState("");
  const [middlename, setmiddles] = useState("");
  const [lastname, setlast] = useState("");
  const [balance, setbalance] = useState("");
  const [phone, setphone] = useState("");
  const validate = () => {
    return (
      (firstname !== "") &
      (lastname !== "") &
      (phone !== "") &
      (middlename !== "") &
      (balance !== "")
    );
  };
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/administration/dealers/`,
      {
        Action: "Post",
        first_name: firstname,
        email_address: "-",
        balance_usd: balance,
        phone_number: phone,
        middle_name: middlename,
        last_name: lastname,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="name">
          <div class="form-group">
            <label>
              First Name:
              <input
                class="form-control"
                type="text"
                value={firstname}
                placeholder="First Name"
                onChange={(e) => setfirst(e.target.value)}
              />
            </label>
          </div>
          <div class="form-group">
            <label>
              Middle Name:
              <input
                class="form-control"
                type="text"
                value={middlename}
                placeholder="Middle Name"
                onChange={(e) => setmiddles(e.target.value)}
              />
            </label>
          </div>

          <div class="form-group">
            <label>
              Last Name:
              <input
                class="form-control"
                type="text"
                value={lastname}
                placeholder="Last Name"
                onChange={(e) => setlast(e.target.value)}
              />
            </label>
          </div>
        </div>

        <label>
          Phone:
          <input
            class="form-control"
            type="number"
            value={phone}
            placeholder="phone"
            onChange={(e) => setphone(e.target.value)}
          />
        </label>

        <label>
          Balance:
          <input
            class="form-control"
            type="text"
            value={balance}
            placeholder="Balance"
            onChange={(e) => setbalance(e.target.value)}
          />
        </label>
        <div className="fo">
          <button
            type="submit"
            className="btn btn-primary btn-md"
            onClick={() => {
              props.parentFunction();
            }}
            disabled={!validate()}
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
}

export default Form;
