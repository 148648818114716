// AdminConsole.js

import React, { useState, useEffect, useContext } from "react";
import "./AdminConsole.css"; // Import the CSS file
import { API_URL } from "../components/api";
import TransactionsTable from "./transactionstb";
import AuthContext from "../context/AuthContext";
import axios from "axios";

function AdminConsole() {
  const [data, setData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/dealers/info/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/dealers/transaction/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setTransactions(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTransactions();
    fetchData();
  }, [user.username]);

  return (
    <div className="admin-console">
      <div className="admin-details">
        <h4>Company:</h4>
        <h4>{data.company_name}</h4>
        <h4>Balance: {" $ " + data.credits}</h4>
      </div>
      <div className="transactions">
        <TransactionsTable transactions={transactions} />
      </div>
    </div>
  );
}

export default AdminConsole;
