import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function Wizard(props) {
  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [username, setusername] = useState("");
  const [subnotes, setnotes] = useState("-");
  const [firstname, setfirst] = useState("");
  const [lastname, setlast] = useState("");
  const [password, setpassword] = useState("");
  let [plans, setplans] = useState([]);
  const [connectivity, setcona] = useState("");

  const [plani, setplano] = useState("");

  console.log(plani);
  const validate = () => {
    return (
      (username !== "") &
      (firstname !== "") &
      (lastname !== "") &
      (connectivity !== "") &

      (plani !== "")
    );
  };

  useEffect(() => {
    let isMounted = true;

    const getplans = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/plans/internet`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setplans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getplans();

    return () => {
      isMounted = false;
    };
  }, []);

  const plano = plans.map((item) => {
    const planNameWithoutSM = item.plan_name.replace("SM-", "");
    return {
      label: planNameWithoutSM,
      value: item.internet_plan_id,
    };
  });

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };
  const cona = [
    { value: "Fiber", label: "Fiber" },
    { value: "Wireless", label: "Wireless" },
  ];
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/wizard/`,
      {
        Action: "Post",
        username: username,
        plan_id: plani,
        first_name: firstname,
        last_name: lastname,
        password: password,
        notes: subnotes,
        connectivity: connectivity,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              First Name:
              <input
                className="form-control"
                type="text"
                value={firstname}
                required
                placeholder="First Name"
                onChange={(e) => {
                  setfirst(e.target.value);
                }}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Last Name:
              <input
                className="form-control"
                type="text"
                value={lastname}
                required
                placeholder="Last Name"
                onChange={(e) => setlast(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Username:
              <input
                className="form-control"
                type="text"
                value={username}
                required
                placeholder="Username"
                onChange={(e) => setusername(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Password:
              <input
                className="form-control"
                type="text"
                value={password}
                required
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Plan Name:
              <Select
                className="search-line"
                placeholder="Choose plan"
                options={plano}
                onChange={(opt) => setplano(opt.value)}
              />
            </label>
          </div>

          <label>
            Notes:
            <input
              className="form-control"
              type="text"
              value={subnotes}
              required
              placeholder="Notes"
              onChange={(e) => setnotes(e.target.value)}
            />
          </label>
          <label>
            Connectivity Option:
            <Select
              className="search-line"
              placeholder="Choose Option"
              options={cona}
              onChange={(opt) => setcona(opt.value)}
            />
          </label>
          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              disabled={!validate()}
            >
              Create
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Wizard;
