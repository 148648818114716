import React, { useState, useEffect, useContext } from "react";
import PlansTable from "./planstb";
import Modal from "react-bootstrap/Modal";
import Formp from "./createplans";
import Formpr from "./createresellerplans";
import "./plans.css";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { API_URL } from "../components/api";

function Plans() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);

  const handleShowAdd = () => setShowAdd(true);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user.username]);
  return (
    <div className="Plans">
      <div className="main">
        <div>
          {data === "yess" && (
            <button onClick={handleShow} className="AddClient">
              Add Plan
            </button>
          )}
          {data === "yess" && (
            <button onClick={handleShowAdd} className="AddClient">
              Add Resller Plan
            </button>
          )}
          <Modal show={show} onHide={handleClose}>
            <div className="forma">
              <Modal.Header closeButton>
                <Modal.Title>Add Plan</Modal.Title>
              </Modal.Header>
              <Formp />
            </div>
          </Modal>
          <Modal show={showAdd} onHide={handleCloseAdd}>
            <div className="forma">
              <Modal.Header closeButton>
                <Modal.Title>Add Plan</Modal.Title>
              </Modal.Header>
              <Formpr />
            </div>
          </Modal>
        </div>
        <PlansTable />
      </div>
    </div>
  );
}

export default Plans;
