import React, { useState } from "react";
import Sidebar from "../components/sidebar";
import Modal from "react-bootstrap/Modal";
import DealerTable from "./dealertb";
import Form from "./Createform";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";

function Dealers() {
  return (
    <div className="Clients">
      <div className="main">
        <div>
          <button className="add-button">
            <NavLink
              exact
              to="/plans/reseller"
              activeClassName="active"
              className="nav-inner"
            >
              Sub Reseller Plans
            </NavLink>
          </button>
          <NavLink
            exact
            to="/reseller/details/"
            activeClassName="active"
            className="AddClient"
            style={{
              textDecoration: "none",
              margin: "10px 10px 10px 10px",
              color: "white",
            }}
          >
            More Details
          </NavLink>
        </div>
        <div className="testo">
          <DealerTable />
        </div>
      </div>
    </div>
  );
}

export default Dealers;
