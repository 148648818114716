import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./Pages/context/AuthContext"; // Import the AuthProvider
import Dashboard from "./Pages/Dashboard/dashboard";
import Plans from "./Pages/InternetPlans/Plans";
import ResellerPlans from "./Pages/ResellerPlans/Plans";
import PlansRes from "./Pages/Resellers/Plans";
import Clients from "./Pages/Clients/clients";

import Dealers from "./Pages/Dealers/dealers";
import DealerDetails from "./Pages/Dealers/dealerDetails";

import AdminConsole from "./Pages/AdminConsole/AdminConsole";

import DealerSub from "./Pages/DealerSub/internetsub";
import DealerSubsss from "./Pages/DealerSubss/internetsub";
import Reseller from "./Pages/Reseller/Reseller";

import Header from "./Pages/components/Header";
import LoginPage from "./Pages/Login/LoginPage";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route
              render={() => (
                <div>
                  <Route component={Header} />
                  <Switch>
                    <PrivateRoute component={Dashboard} path="/" exact />
                    <PrivateRoute component={Clients} path="/clients" exact />

                    <PrivateRoute
                      component={Plans}
                      path="/plans/internet"
                      exact
                    />
                    <PrivateRoute
                      component={ResellerPlans}
                      path="/plans/resellers"
                      exact
                    />
                    <PrivateRoute component={Dealers} path="/resellers" exact />
                    <PrivateRoute
                      component={PlansRes}
                      path="/plans/reseller"
                      exact
                    />
                    <PrivateRoute
                      component={DealerSub}
                      path="/subscriptions/internet"
                      exact
                    />
                    <PrivateRoute
                      component={DealerSubsss}
                      path="/subscriptions/internet/reseller"
                      exact
                    />

                    <PrivateRoute
                      component={AdminConsole}
                      path="/admin/console"
                      exact
                    />
                    <PrivateRoute
                      component={Dealers}
                      path="/internet/dealers"
                      exact
                    />
                    <PrivateRoute
                      component={DealerDetails}
                      path="/reseller/details/"
                      exact
                    />
                  </Switch>
                </div>
              )}
            />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
