import React, { useState, useContext, useEffect } from "react";
import { API_URL } from "../components/api";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";

import AuthContext from "../context/AuthContext";
import axios from "axios";

function Dashboard(props) {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    online: 0,
    offline: 0,
    expired: 0,
    inactive: 0,
    hold: 0,
    light: 0,
    popular: 0,
    gold: 0,
    basic: 0,
    premium: 0,
    platinum: 0,
    diamond: 0,
  });

  const fetchData = (option) => {
    axios
      .get(`${API_URL}/radius/reseller/dashboard/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          dealer: props.selectedOption,
          option: option,
        },
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          [option]: res.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPermissions = async () => {
    try {
      const response = await axios.get(`${API_URL}/administration/groups/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setGroup(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false once permissions are fetched
    }
  };

  useEffect(() => {
    fetchData("online");
    fetchData("offline");
    fetchData("expired");
    fetchData("inactive");
    fetchData("hold");
    fetchData("basic");
    fetchData("light");
    fetchData("popular");
    fetchData("premium");
    fetchData("gold");
    fetchData("platinum");
    fetchData("diamond");

    getPermissions();
  }, [authTokens.access, user.username]);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container fluid className="Dashboard">
      <Row className="top-cards">
        <>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.online}</h4>
                <hr />
                <p>Online</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.offline}</h4>
                <hr />
                <p>Offline</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.expired}</h4>
                <hr />
                <p>Expired</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.hold}</h4>
                <hr />
                <p>On-Hold</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.inactive}</h4>
                <hr />
                <p>Inactive</p>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.basic}</h4>
                <hr />
                <p>basic</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.light}</h4>
                <hr />
                <p>light</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.popular}</h4>
                <hr />
                <p>popular</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.premium}</h4>
                <hr />
                <p>premium</p>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.gold}</h4>
                <hr />
                <p>Gold</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.platinum}</h4>
                <hr />
                <p>Platinum</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.diamond}</h4>
                <hr />
                <p>Diamond</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Card className="text-center">
              <Card.Body>
                <h4>{data.online + data.offline}</h4>
                <hr />
                <p>Total</p>
              </Card.Body>
            </Card>
          </Col>
        </>
      </Row>
    </Container>
  );
}

export default Dashboard;
