import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function Formsa(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let intsub = props.intsu;
  console.log(intsub);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  let intplan = props.intpa;
  let sub = props.su;
  let u = props.usr;
  let f = props.full;
  let s = props.sit;
  let c = props.con;
  let o = props.opti;
  let p = props.plana;
  let exp = props.exp;
  let du = props.due;
  let no = props.notes;

  console.log(exp);

  const [username, setusername] = useState("");
  const [pass] = useState(props.pass);

  const [password, setpassword] = useState("");
  const [site, setsite] = useState("");
  const [due, setdue] = useState("");
  const [subnotes, setnotes] = useState("");
  const [suffix, setsuffix] = useState("-");

  const [consumption_option, setcon] = useState("");
  const [connectivity, setcona] = useState("");
  const [expiration_date, setda] = useState("");
  const [expiryDateDisabled, setExpiryDateDisabled] = useState(false);
  let [plans, setplans] = useState([]);
  const [client, setclient] = useState("");
  const [plani, setplano] = useState(intplan);
  let [notes, setcil] = useState([]);
  console.log(expiration_date);
  console.log(plani);
  console.log(client);
  useEffect(() => {
    let isMounted = true;

    const getclients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setcil(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getplans = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/plans/resellers`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setplans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getpermisions = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setExpiryDateDisabled(response.data === "Dealer");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getpermisions();
    getclients();
    getplans();

    return () => {
      isMounted = false;
    };
  }, []);
  const options = notes.map((item) => {
    return {
      label: item.fullname_en,
      value: item.client_id,
    };
  });

  const plano = plans.map((item) => {
    const planNameWithoutSM = item.plan_name.replace("SM-", "");
    return {
      label: planNameWithoutSM,
      value: item.internet_plan_id,
    };
  });
  const opta = [
    { value: "LIMITED", label: "LIMITED" },
    { value: "UNLIMITED", label: "UNLIMITED" },
  ];
  const cona = [
    { value: "Fiber", label: "Fiber" },
    { value: "Wireless", label: "Wireless" },
  ];
  const dua = [
    { value: "First", label: "First" },
    { value: "Half", label: "Half" },
  ];

  // Function to show the message for a given duration
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/`,
      {
        Action: "Put",
        Subscription_id: sub,
        internet_subscription_id: intsub,
        internet_plan_id: intplan,
        username: username,
        client_id: client,
        plan_id: plani,
        consumption_option: consumption_option,
        connectivity: connectivity,
        expiry_date: expiration_date,
        site: site,
        due: due,
        suffix: suffix,
        notes: subnotes,
        password: password,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        // refreshPage(); // You may or may not want to reload the page after successful submission
        showMessage("Edit successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Edit failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Username:
              <input
                className="form-control"
                type="text"
                value={username}
                placeholder={u}
                onChange={(e) => setusername(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Password:
              <input
                className="form-control"
                type="text"
                value={password}
                placeholder={pass}
                onChange={(e) => setpassword(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Client Name:
              <Select
                className="search-line"
                placeholder={f}
                options={options}
                onChange={(opt) => setclient(opt.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Plan Name:
              <Select
                className="search-line"
                placeholder={p}
                options={plano}
                onChange={(opt) => setplano(opt.value)}
              />
            </label>
          </div>

          <label>
            Notes:
            <input
              className="form-control"
              type="text"
              value={subnotes}
              placeholder={no}
              onChange={(e) => setnotes(e.target.value)}
            />
          </label>
          <label>
            Expiry Date:
            <input
              type="date"
              id="expiryDate"
              name="expiryDate"
              className="form-control"
              defaultValue={exp ? exp.substr(0, 10) : ""}
              onChange={(e) => setda(e.target.value)}
              disabled={expiryDateDisabled}
            />
          </label>
          <label>
            Conectivity Option:
            <Select
              className="search-line"
              placeholder={c}
              options={cona}
              onChange={(opt) => setcona(opt.value)}
            />
          </label>
          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Edit
            </button>
          </div>
        </form>
      </div>

      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Formsa;
